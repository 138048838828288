@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@600&display=swap");

//$brand-color: #fccc2d;
//$brand-color: #ea1de3;
//$brand-color-2: #1dcfea;

$brand-color: #24df8d;
$brand-color-2: #0047ff;
$brand-color-3: #45820c;

$night-color: rgb(204, 204, 197);
//$night-bg: #1f1f1d;
$night-bg: #030303;
$night-dark-bg: darken($night-bg, 6%);
$night-darker-bg: darken($night-bg, 7%);
//$night-tint-bg: lighten(#1f1f1d,5%);
$night-tint-bg: lighten(rgb(31, 37, 34), 5%);
//$night-bg: rgb(24, 24, 23);
$night-border: lighten($night-dark-bg, 5%);

$day-color: invert($night-color);
$day-bg: lighten(invert($night-bg), 8%);
$day-darker-bg: darken($day-bg, 2.2%);
$day-border: darken($day-bg, 5%);

$big-radius: 1rem;

body,
.text-body,
.bg-body.border-color,
.border-body,
.header,
.footer,
.search {
	//transition: all ease-in-out 0.3s;
}
.side-col {
	transition: background-color ease-in-out 0.3s, border-color ease-in-out 0.3s;
}

.fixed-top {
	z-index: 98;
}

body {
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	padding-top: 72px;
	font-size: 16px;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 400;
		font-family: "Roboto", sans-serif;
	}
	.content {
	}
	.algo {
	}
	&.home {
		padding-top: 0;
	}
	#welcome {
		padding: $big-radius;
		overflow: hidden;
		position: relative;
		height: 80%;
		width: 100%;
		min-height: 100vh;
		margin-top: -72px;
		text-align: center;
		overflow: hidden;
		backdrop-filter: blur(5px);
		.cta {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: 420px;
			width: 90%;
			z-index: 999;
			.logo {
				animation-name: slowRock;
				animation-duration: 20s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-in-out;
				-webkit-filter: drop-shadow(5px 5px 5px #000);
				filter: drop-shadow(5px 5px 5px #000);
			}
			.explore {
			}
			/* The animation code */
			@keyframes slowRock {
				0% {
					transform: rotate(-2deg) scale(1);
				}
				25% {
					transform: scale(1.1);
				}
				50% {
					transform: rotate(2deg) scale(1);
				}
				75% {
					transform: scale(1.1);
				}
				100% {
					transform: rotate(-2deg) scale(1);
				}
			}
			@keyframes slowRockAlt {
				0% {
					transform: rotate(4deg);
				}
				50% {
					transform: rotate(-4deg);
				}
				100% {
					transform: rotate(4deg);
				}
			}
		}
	}
	&.shufl {
		background-color: #469593;
		background: url(./images/banner.png) no-repeat top center,
			url(./images/collection.jpg) no-repeat center center,
			url(./images/banner.png) no-repeat bottom center;
		background-size: 100% 20%, 100% 60%, 100% 20%;
		color: $night-color;
		position: relative;

		a {
			color: rgb(13, 202, 240);
			&:hover {
				color: darken(rgb(13, 202, 240), 5%);
			}
			&.muted-link {
				color: darken($night-color, 50%) !important;
				text-decoration: none;
				&:hover {
					color: $night-color !important;
				}
			}
		}
		.muted-link {
			color: darken($night-color, 50%) !important;
			text-decoration: none;
			padding: 0;
			margin: 0;
			background-color: transparent;
			border: none;
			&:hover {
				color: $night-color !important;
			}
		}
		.btn-back {
			color: lighten($night-darker-bg, 25%);
			background-color: $night-darker-bg !important;
			border: solid 1px $night-border;
			border-radius: $big-radius;
			&:hover {
				color: lighten($night-color, 10%);
				background-color: lighen($night-darker-bg, 5%);
			}
		}
		.collection-img {
			border: solid 4px #fff;
		}
		/*
    &:after{
      display: block;
      content: ' ';
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      top: 0;
      left:0;
      right: 0;
      bottom: 0;
      position: fixed;
      background-image: url(./images/FF-background.jpg);
      background-size: cover;
      filter: saturate(0.8);
      z-index: -1;
      opacity: 0.30;
    }*/
		.dropdown-menu {
			margin-top: 16px;
			border-radius: 0 0 $big-radius $big-radius;
			background-color: rgba(#000, 0.8);
			border-color: $night-border;
			label {
				display: block;
			}
			.dropdown-item,
			label {
				color: rgba($night-color, 0.8);
				padding: 0.25rem 1rem;
				&:hover {
					color: #000 !important;
					text-shadow: 1px 1px 0 rgba($brand-color, 0.2);
					background: $brand-color;
					background: linear-gradient(
						to bottom,
						$brand-color -50%,
						$brand-color-3 100%
					);
					border-color: $brand-color;
				}
			}
			input[type="radio"] {
				display: none;
			}
			input[type="radio"]:checked {
				+ label {
					color: #fff !important;
					background-color: transparent !important;
					background: none !important;
					&:hover {
						color: #fff !important;
						background-color: transparent !important;
						background: none !important;
					}
				}
			}
		}
		.form-select {
			&:focus {
				outline: none !important;
				box-shadow: none !important;
				border-color: $brand-color !important;
			}
			&:hover {
				border-color: lighten($night-border, 3%) !important;
			}
		}
		.header {
			border: none;

			top: 0px;
			right: 0;
			left: 0;
		}

		.nav-link {
			color: $night-color;
			&:hover,
			&.active {
				color: rgba(255, 255, 255, 1);
			}
		}
		.shadow {
			box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
		}
		.shadow-light {
			box-shadow: 0 0 20px rgba($night-bg, 0.4);
		}
		.bg-body-glass {
			background-color: rgba($night-dark-bg, 0.8) !important;
			backdrop-filter: blur(2px) !important;
			//background-color: $night-dark-bg !important;
		}

		.bg-body {
			background-color: $night-dark-bg !important;
		}
		.bg-brand {
			background-color: $brand-color !important;
		}
		.bg-partial-fade {
			background-color: rgba($night-dark-bg, 0.35) !important;
		}
		.bg-body-dark {
			background-color: darken($night-dark-bg, 2%) !important;
		}
		.bg-brand {
			background-color: $brand-color !important;
		}
		.bg-brand-2 {
			background-color: $brand-color-2 !important;
		}
		.border-body {
			border-color: darken($night-bg, 5%) !important;
		}
		.border-brand {
			border-color: $brand-color !important;
		}
		.border-body-light {
			border-color: lighten($night-border, 5%) !important;
		}

		.border-brand {
			border-color: $brand-color !important;
		}

		.form-select {
			color: #fff !important;
			&.has-value {
				color: #fff !important;
				border-color: $brand-color !important;
			}
		}

		.text-body {
			color: #fff !important;
		}
		.text-brand {
			color: $brand-color !important;
		}
		.text-brand-2 {
			color: $brand-color-2 !important;
		}
		.text-brand-3 {
			color: $brand-color-3 !important;
		}

		.text-body-50 {
			color: rgba(255, 255, 255, 0.5) !important;
		}
		.text-body-25 {
			color: rgba(255, 255, 255, 0.25) !important;
		}

		.text-brand-50 {
			color: rgba($brand-color, 0.5) !important;
		}
		.text-brand-25 {
			color: rgba($brand-color, 0.25) !important;
		}

		.side-col {
			//box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
			//border-radius: $big-radius;

			//border: solid 1px $night-border;
			.collapse,
			.collapsing {
				background-color: rgba(darken($night-darker-bg, 1%), 0.5);
			}
			button {
				&.filter {
					width: 100%;
					text-align: left;
					color: $night-color;
					text-decoration: none;
					border: none;
					background-color: transparent;
					position: relative;
					&:after {
						font-family: "Font Awesome 6 Pro";
						font-weight: 200;
						font-size: 18px;
						display: block;
						position: absolute;
						top: 50%;
						right: 20px;
						transform: translateY(-50%);
						color: $night-color;
					}
					color: $brand-color;
					&:after {
						color: $brand-color;
						content: "\f106";
					}
					&.collapsed {
						color: rgba(255, 255, 255, 0.75);
						&:after {
							color: $night-color;
							content: "\f107";
						}
					}
					&:hover {
						color: $brand-color;
						&:after {
							color: $brand-color;
						}
					}
				}
			}
			a {
				color: $night-color;
				text-decoration: none;
				&:hover {
					color: $brand-color;
				}
				&.filter {
					color: $brand-color;
					&.collapsed {
						color: rgba(255, 255, 255, 0.75);
					}

					&:hover {
						color: rgba(255, 255, 255, 1);
					}
				}
				.filter-thumb {
					border-radius: 50%;
					background-color: $night-bg !important;
					transition: all ease-in-out 0.3s;
					display: block;
					border-width: 1px;
					border-style: solid;
					border-color: $night-border !important;
					margin-top: 2px;
					margin-bottom: 2px;
					&:hover {
						background-color: $brand-color !important;
					}
					&.active {
						position: relative;
						&:before {
							display: block;
							position: absolute;
							width: 15px;
							height: 15px;
							border-radius: 50%;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							background-color: $brand-color;
							box-shadow: 0 0 5px #000;
							color: #000;
							font-family: "Font Awesome 6 Pro";
							font-weight: 900;
							content: "\f00c";
							font-size: 11px;
							text-align: center;
						}
					}
				}
			}
		}
		.bar {
			color: $night-color;
			background-color: darken($night-darker-bg, 0%);
		}
		.footer {
			text-shadow: 1px 1px 10px #000;
			color: rgba(255, 255, 255, 0.75);
			padding-top: 60px !important;
			// padding-bottom: 60px !important;
			/*background:rgba(0,0,0,0.0);
      box-shadow:0 -20px 20px rgba(0,0,0,0.0);
      */
			a {
				color: rgba(255, 255, 255, 0.75);
				text-decoration: none;
				img {
					opacity: 0.75;
					box-shadow: 1px 1px 10px #000;
				}
				&:hover {
					color: rgba(255, 255, 255, 1);
					img {
						opacity: 1;
					}
				}
			}
			.btn {
				&:hover {
					color: black;
				}
			}
		}
		.searchwrapper {
			position: relative;
			display: block;
			width: 100%;
			max-width: 400px;
			margin: 0 auto;
			.search-btn {
				position: absolute;
				top: 1px;
				right: 0;
				bottom: 1px;
				padding: 0 10px;
				border: none;
				background: transparent;
				z-index: 1;
				color: rgba(255, 255, 255, 0.5);
				border-left: solid 1px $night-border;
				&:hover {
					color: rgba(255, 255, 255, 1);
				}
			}
			/*
      &:before{
        font-family: "Font Awesome 6 Pro";
        font-weight: 300;
        content: '\f002';
        display: block;
        left: 10px;
        top:7px;
        width: 16px;
        height: 24px;
        font-size:12px;
        line-height: 24px;
        position: absolute;
        z-index:1;
        pointer-events: none;
      }
      */
			.search {
				background-color: darken($night-darker-bg, 1%) !important;
				box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.5);
				border-color: $night-border !important;
				color: $night-color;
				position: relative;
				padding-right: 40px;
				&::placeholder {
					color: rgba(255, 255, 255, 0.5);
				}
				&:focus {
					color: $night-color;
					border-color: $brand-color !important;

					border-color: transparent !important;
					background: linear-gradient(#111413, #111413) padding-box,
						linear-gradient(to right, $brand-color, $brand-color-2) border-box;

					outline: none !important;
					box-shadow: none !important;
				}
				&:hover {
					&::placeholder {
						color: rgba(255, 255, 255, 0.75);
					}
				}
			}
		}
		.col-md-4 {
			.card {
				.collectionBg {
					padding: 12.5vh 0 0 !important;
				}
			}
		}
		.card {
			border-color: $night-border;
			&:hover {
				//background-color: darken($night-dark-bg,2%) !important;
			}
			.card-img {
				box-shadow: inset 0 0 5px #000;
				border-radius: calc(#{$big-radius} - 4px);
			}
			.z-index {
				z-index: 1;
			}
			.collectionBg {
				background-position: center center;
				background-size: cover;
				background-repeat: no-repeat;
				position: relative;
				padding: 25vh 0 0;
				overflow: hidden;
				z-index: 0;
				&:after {
					z-index: 1;
					position: absolute;
					left: 0;
					top: calc(100% - 50px);
					bottom: auto;
					right: 0;
					width: 100%;
					content: " ";
					height: 100%;
					background-color: $night-dark-bg !important;
				}
				img {
					display: block !important;
					position: relative;
					z-index: 2;
					width: 100px;
					/*
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);;
          */
				}
			}
			&.rounded-big {
				.collectionBg {
					border-radius: calc(#{$big-radius} - 4px) calc(#{$big-radius} - 4px) 0
						0;
				}
			}

			.card-img-top {
				box-shadow: inset 0 0 5px #000;
				border-radius: calc(#{$big-radius} - 1px) calc(#{$big-radius} - 1px) 0 0;
				&:before {
					display: block;
					content: "";
					position: absolute;
				}
			}
			.card-img-bottom {
				border-radius: 0 0 $big-radius $big-radius;
			}
			.card-body {
				padding: 10px 10px 10px;
			}
			.card-header {
				border-bottom: solid 1px $night-border;
			}
			.card-footer {
				border-top: solid 1px $night-border;
			}
		}
		a.card:hover {
			background-color: darken($night-dark-bg, 2%) !important;
		}

		input[type="search"]::-webkit-search-cancel-button {
			filter: invert(100);
		}
		.property {
			border-style: solid;
			border-width: 1px;
			border-radius: 1rem !important;
			border: none !important;
			background: linear-gradient(to bottom, $brand-color, $brand-color-3);
			text-transform: capitalize;
		}
		.btn-primary {
			color: #000 !important;
			text-shadow: 1px 1px 0 lighten($brand-color, 10%);
			background: $brand-color;
			background: linear-gradient(to bottom, $brand-color, $brand-color-3);
			border-color: $brand-color;

			&:hover,
			&:focus {
				color: #000 !important;
				text-shadow: 1px 1px 0 lighten($brand-color, 15%);
				background: lighten($brand-color, 15%);
				background: linear-gradient(
					to bottom,
					lighten($brand-color, 15%),
					lighten($brand-color-3, 15%)
				);
				border-color: lighten($brand-color, 15%);
			}
		}
		.btn-info {
			color: lighten($brand-color-2, 50%) !important;
			background-color: $brand-color-2;
			border-color: $brand-color-2;

			&:hover,
			&:focus {
				color: lighten($brand-color-2, 50%) !important;
				background-color: lighten($brand-color-2, 10%);
				border-color: lighten($brand-color-2, 10%);
			}
		}

		.btn-outline-primary {
			color: $brand-color !important;
			border-color: $brand-color;

			&:hover,
			&:focus {
				color: #000000 !important;
				background-color: lighten($brand-color, 10%);
				border-color: lighten($brand-color, 10%);
			}
		}
	}
}
.header {
	padding: 1.5rem 0 !important;
	border-bottom-style: solid;
	border-bottom-width: 1px;
}
.navBarHeader {
	background-image: url(./images/banner3.png);
	background-size: contain;
	background-repeat: no-repeat;
}

@media screen and (max-width: 1000px) {
	.navBarHeader {
		background-image: none;
	}
}
.search {
	width: 100%;
	min-width: 350px;
	border-radius: 0.75rem;
	display: block;
	border-width: 1px;
	border-style: solid;
	&:focus {
		outline: none !important;
		box-shadow: none !important;
	}
}

.side-col {
	position: fixed;
	overflow: auto;
	margin: 0;
	padding-top: 0px !important;
	z-index: 10;
	left: 0;
	top: 72px;

	max-height: calc(100% - 73px);
	min-height: calc(100% - 73px);

	border-bottom: 0 !important;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	&.bottom-filter {
		/*
    position: absolute;
    top: calc(73px + 3rem);
    bottom: -3rem;
    width: 100%;
    max-height: none !important;
    */

		position: absolute;
		top: inherit;
		bottom: 0px;
		width: 100%;
		max-height: calc(100vh - 73px);
		min-height: calc(100vh - 73px);
	}
}
.logo-col,
.psuedo-side-col {
	max-width: 300px;
	position: inherit;
	box-sizing: content-box;
}
.box-content {
	box-sizing: content-box;
	position: relative;
	min-height: calc(100vh - 72px);
}
.side-col {
	width: inherit;
	max-width: inherit;
}

.side-col {
	&::-webkit-scrollbar {
		display: none;
	}

	&.collapse-col {
		max-width: 53px;
	}
}

.col-md-3.collapse-col {
	max-width: 53px;
}

.side-col {
	&.collapse-col {
		.collapse,
		.txt {
			display: none !important;
		}
	}

	.expand {
		display: none !important;
	}

	&.collapse-col .expand {
		display: block !important;
	}
}

.filter {
	text-decoration: none;
	display: block;
	transition: all ease 0.3s;
}

.form-switch {
	box-shadow: none !important;
	outline: none !important;

	.form-check-input {
		box-shadow: none !important;
		outline: none !important;
		width: 2em;
		margin-left: -2.5em;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23$brand-color'/%3e%3c/svg%3e") !important;
		background-position: left center;
		border-color: $brand-color;
		color: $brand-color;
		outline: none;

		&:checked {
			background-position: right center;
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e") !important;
		}
	}
}

.form-check-input {
	background-color: #000;
	color: $brand-color;

	&:checked {
		background-color: $brand-color;
		border-color: $brand-color;
	}

	&:before {
		font-family: "Font Awesome 6 Free";
		font-weight: 900;
		content: "\f185";
		display: block;
		position: absolute;
		left: auto;
		right: 7px;
		top: 7px;
		font-size: 13px;
		color: rgba(252, 220, 45, 0.7);
	}

	&:active:before {
		font-family: "Font Awesome 6 Free";
		font-weight: 900;
		content: "\f185";
		display: block;
		position: absolute;
		left: auto;
		right: 7px;
		top: 7px;
		font-size: 13px;
		color: rgba(252, 220, 45, 0.7);
		right: 6px;
		top: 2px;
	}

	&:checked {
		&:before {
			font-family: "Font Awesome 6 Free";
			font-weight: 900;
			content: "\f186";
			display: block;
			position: absolute;
			left: 7px;
			top: 7px;
			font-size: 13px;
			color: rgba(0, 0, 0, 0.4);
		}

		&:active:before {
			font-family: "Font Awesome 6 Free";
			font-weight: 900;
			content: "\f186";
			display: block;
			position: absolute;
			left: 7px;
			top: 7px;
			font-size: 13px;
			color: rgba(0, 0, 0, 0.4);
			left: 6px;
			top: 2px;
		}
	}
}

.form-switch.form-switch-md {
	margin-bottom: $big-radius;
	.form-check-input {
		height: 1.5rem;
		width: calc(2rem + 0.75rem);
		border-radius: 3rem;
	}
}

.btn {
	&:disabled {
		cursor: not-allowed;
		pointer-events: all;
		background: #f44 !important;
		border-color: #f44 !important;
	}
}

.rounded-big {
	border-radius: $big-radius !important;
}
.rounded-big-bottom {
	border-radius: 0 0 $big-radius $big-radius !important;
}
.section {
	padding: 3rem !important;
}

.card-img,
.card-img-top {
	border-top-left-radius: calc(#{$big-radius} - 1px);
	border-top-right-radius: calc(#{$big-radius} - 1px);
	border-bottom-left-radius: calc(#{$big-radius} - 1px);
	border-bottom-right-radius: calc(#{$big-radius} - 1px);
}

.parallax {
	display: block;
	height: 35vh;
	overflow: hidden;
	background-position: 0 38%;
	background-size: cover;
	box-shadow: inset 0 -20px 50px rgba(0, 0, 0, 0.6);
}

.tooltip-inner {
	opacity: 1;
	background-color: $brand-color !important;
	color: black !important;
}

.tooltip {
	&.bs-tooltip-top .tooltip-arrow::before {
		border-top-color: $brand-color;
	}

	&.bs-tooltip-bottom .tooltip-arrow::before {
		border-bottom-color: $brand-color;
	}

	&.bs-tooltip-start .tooltip-arrow::before {
		border-left-color: $brand-color;
	}

	&.bs-tooltip-end .tooltip-arrow::before {
		border-right-color: $brand-color;
	}
}
a.card {
	text-decoration: none;
}

.bg-none {
	background: none !important;
}
.border-none {
	border: none !important;
}
.modal {
	background-size: cover;
	background-attachment: fixed;
}
.btn-close {
	filter: invert(100);
}
.justify-center {
	justify-content: center;
}

.bg-light {
	background: lighten($night-bg, 4%) !important;
}
.bg-dark {
	background: lighten($night-bg, 2%) !important;
}
.bg-dark-fade {
	background: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(5px);
}
/*shuffle*/

.stack {
	position: relative;
	max-width: 420px;
	display: block;
	margin: 0 auto;
}
.shuffle {
	position: absolute;
	animation: cardShuffle 6.51s infinite;
	animation-delay: calc(1s * var(--i));
	transform-style: preserve-3d;

	&.holder {
		position: relative;
		animation: none !important;
		z-index: inherit !important;
		opacity: 0;
	}
}
$slide-card: translate(-80%, -40%) scaleX(0.05) skewY(7deg);
@keyframes cardShuffle {
	0% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
	5% {
		transform: translate(-80%, -10%) scaleX(0.05) skewY(7deg);
	}
	25% {
		transform: translate3d(0, 0, 0) scale(0.8);
	}
	29% {
		z-index: 0;
	}
	35% {
		transform: scale(1);
		transform: translate3d(0, 0, 0);
	}
	35%,
	80% {
		transform: translate3d(0, 0, 0);
	}
}

.ps-24 {
	padding-left: 12px;
}
.algo {
	width: 11px;
	height: auto;
	vertical-align: baseline;
	filter: invert(1);
	&.big {
		width: 22px;
	}
	&.small {
		width: 8px;
	}
}
.container-fluid {
	width: 94%;
	max-width: 1800px;
	&.md {
		max-width: 900px;
	}
	&.sm {
		max-width: 700px;
	}
}
.btn-group-sm > .btn,
.btn-sm {
	border-radius: $big-radius;
}

.mb-n-5 {
	margin-bottom: -3rem !important;
}
.mb-n-10 {
	margin-bottom: -6rem !important;
}

.max-width-300px {
	max-width: 300px;
}
.max-width-400px {
	max-width: 400px;
}
.max-width-500px {
	max-width: 500px;
}
.max-width-600px {
	max-width: 600px;
}

small {
	&.tiny {
		font-size: 14px !important;
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.mt-neg-header {
	margin-top: -3rem !important;
}

.autocomplete {
	position: relative;
	display: inline-block;
}
.autocomplete-items {
	position: absolute;
	border-top: none;
	border-bottom: none;
	overflow: hidden;
	z-index: 99;
	/*position the autocomplete items to be the same width as the container:*/
	top: 100%;
	left: 0;
	right: 0;
	margin-top: 10px;
	text-align: left;
	border-radius: $big-radius;
	max-height: 182px;
	border: solid 1px $night-border;
	background-color: darken($night-darker-bg, 1%) !important;
	box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.5);
}
.autocomplete-items div {
	padding: 10px;
	cursor: pointer;
	background-color: transparent;
	border-bottom: 1px solid $night-border;
}
.autocomplete-items div:hover {
	/*when hovering an item:*/
	background-color: $night-darker-bg;
	color: $brand-color;
}
.autocomplete-active {
	/*when navigating through the items using the arrow keys:*/
	background-color: $brand-color !important;
	color: $night-darker-bg;
}

.show-mobile {
	display: none;
}
@media only screen and (max-width: 992px) {
	.navbar-collapse {
		background: $night-color;
		border-radius: $big-radius;
	}
	.show-mobile {
		display: block;
	}

	.limit-attributes {
		max-height: 228px;
		overflow: hidden;
		&.more {
			max-height: 285px;
			position: relative;
			&:after {
				content: " ";
				display: block;
				position: absolute;
				bottom: 0;
				right: 0;
				left: 0;
				top: auto;
				height: 60px;
				background: rgb(0, 0, 0);
				background: -moz-linear-gradient(
					0deg,
					darken($brand-color, 2%) 0%,
					rgba(darken($brand-color, 2%), 0) 100%
				);
				background: -webkit-linear-gradient(
					0deg,
					darken($brand-color, 2%) 0%,
					rgba(darken($brand-color, 2%), 0) 100%
				);
				background: linear-gradient(
					0deg,
					darken($brand-color, 2%) 0%,
					rgba(darken($brand-color, 2%), 0) 100%
				);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
				transition: all ease-in-out 0.3s;
				opacity: 1;
			}
			&.expand {
				max-height: 9999px;
				&:after {
					opacity: 0;
				}
			}
		}
		&.expand {
			max-height: 9999px;
			&:after {
				opacity: 0;
			}
		}
	}
}
.sort-style {
	text-align: left !important;
	min-width: 200px;
	button {
		text-align: left !important;
	}
}

a.dropdown-item {
	cursor: pointer;
}

@keyframes reveal {
	0% {
		transform: scale(0.3);
		opacity: 0;
	}
	10% {
		transform: scale(0.1);
		opacity: 0;
	}
	30% {
		transform: scale(1.05);
		opacity: 1;
	}
	40% {
		transform: scale(0.95);
		opacity: 1;
	}
	50% {
		transform: scale(1);
		opacity: 1;
	}

	50% {
		transform: scale(1);
		opacity: 1;
	}
}
.animated-in {
	animation-name: reveal;
	animation-duration: 3s;
	animation-iteration-count: 1;
	animation-fill-mode: both;
	animation-timing-function: ease-in-out;
}

.navbar-collapse {
	z-index: 998;
}

.wonModal::after {
	display: block;
	content: "x";
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
	height: 20px;
	line-height: 20px;
}

.ReactModal__Overlay {
	animation: popout 1s ease;
	-webkit-animation: popout 1s ease;
}

@keyframes popout {
	from {
		transform: scale(0);
	}
	80% {
		transform: scale(1.2);
	}
	to {
		transform: scale(1);
	}
}
@-webkit-keyframes popout {
	from {
		-webkit-transform: scale(0);
	}
	80% {
		-webkit-transform: scale(1.2);
	}
	to {
		-webkit-transform: scale(1);
	}
}
.disabledShuffle {
	opacity: 0.3 !important;
	cursor: none !important;
	pointer-events: none !important;
	position: relative;
	overflow: hidden !important;
	&:before {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		background: rgba($brand-color, 0.3);
		display: block;
		content: "";
		z-index: 1;
	}
}
.ReactModal__Content {
	background: #000 !important;
	border-color: $brand-color !important;
}
.ReactModal__Overlay {
	background: rgba(0, 0, 0, 0.5) !important;
}
.close {
	position: absolute;
	line-height: 20px;
	top: 20px;
	right: 20px;
	height: 20px;
	width: 10px;
	background: none;
	cursor: pointer;
	opacity: 0.5;
	color: $brand-color;
	&:hover {
		opacity: 1;
	}
}
.text-shadow {
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
